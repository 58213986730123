var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: _vm.width, persistent: "" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-toolbar",
        { staticClass: "headline font-weight-bold", attrs: { color: "white" } },
        [_vm._v("\n    " + _vm._s(_vm.title) + "\n  ")]
      ),
      _c(
        "v-card",
        { attrs: { width: _vm.width, flat: "" } },
        [
          _c(
            "v-card-text",
            { staticClass: "py-0 px-4" },
            [
              _vm.toApprove
                ? _c("span", { staticClass: "description" }, [
                    _vm._v("\n        " + _vm._s(_vm.description) + "\n      "),
                  ])
                : _c("v-textarea", {
                    staticClass: "pa-0 ma-0",
                    attrs: {
                      label: _vm.label,
                      name: "text",
                      rules: _vm.rules,
                      maxlength: _vm.maxlength,
                      counter: _vm.maxlength,
                      "no-resize": "",
                      filled: "",
                    },
                    model: {
                      value: _vm.text,
                      callback: function ($$v) {
                        _vm.text = $$v
                      },
                      expression: "text",
                    },
                  }),
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "pt-4 px-4" },
            [
              _c(
                "v-layout",
                { attrs: { "justify-end": "" } },
                [
                  _c(
                    "v-btn",
                    { attrs: { flat: "" }, on: { click: _vm.beforeCancel } },
                    [_vm._v("\n          Cancelar\n        ")]
                  ),
                  _vm.toApprove
                    ? _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: { click: _vm.beforeConfirm },
                        },
                        [_vm._v("\n          Aceitar\n        ")]
                      )
                    : _c(
                        "v-btn",
                        {
                          attrs: { color: "error", disabled: !_vm.text.length },
                          on: { click: _vm.beforeConfirm },
                        },
                        [_vm._v("\n          Recusar\n        ")]
                      ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }