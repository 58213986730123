<template>
  <in-layout>
    <template slot="list">
      <v-container grid-list-md class="px-4">
        <mtr-dialog
          ref="showMtrDialog"
          :selectedMtr="selectedMtr"
        />

        <v-layout column class="ma-2">
          <router-link class="mb-4" style="cursor:pointer" tag="span" :to="{name:'Configurações'}">
            <v-icon size="18">mdi-chevron-left</v-icon>
            Voltar para configurações
          </router-link>

          <!-- MODAL DO FILTRO AVANÇADO -->
          <advanced-filter-modal xs4
                                 :show="showAdvancedFilterModal"
                                 @close="showAdvancedFilterModal = false"
                                 @emit-fields="filterAdvanced"
                                 :key="componentKey"
          />

          <h1>MTRs cadastrados</h1>

          <v-layout row align-end wrap>
            <v-flex xs7 class="pa-1">
              <v-text-field
                placeholder="Pesquisa MTR ..."
                v-model="mtr"
                name="search"
                label="Pesquisar MTR"
                prepend-inner-icon="mdi-magnify"
                clearable
                @click:clear="typing()"
                @input="typing()"
              />
            </v-flex>
            <v-flex xs2 class="pa-1">
              <v-btn color="primary" @click="openAdvancedFilterModal">
                <v-icon color="white">mdi-magnify</v-icon>
                Busca Avançada
              </v-btn>
            </v-flex>

            <v-flex xs3 class="pa-1">
              <v-btn xs5  @click="limparAdvancedFilter" color="primary" >
                <v-icon color="white" >mdi-close</v-icon>
                Limpar Filtros Avançados
              </v-btn>
            </v-flex>
            <!--            <v-flex xs4 class="pa-1">-->
            <!--              <v-layout align-end>-->
            <!--                <v-flex xs6 class="pa-1">-->
            <!--                  <FormatedDatePicker-->
            <!--                    :max="emittedEnd"-->
            <!--                    v-model="emittedStart"-->
            <!--                    label="Data de emissão inicial"-->
            <!--                    placeholder="Selecionar"-->
            <!--                    @change="typing()"-->
            <!--                  />-->
            <!--                </v-flex>-->
            <!--                <v-flex xs6 class="pa-1">-->
            <!--                  <FormatedDatePicker-->
            <!--                    :min="emittedStart"-->
            <!--                    v-model="emittedEnd"-->
            <!--                    label="Data de emissão final"-->
            <!--                    placeholder="Selecionar"-->
            <!--                    @change="typing()"-->
            <!--                  />-->
            <!--                </v-flex>-->
            <!--              </v-layout>-->
            <!--            </v-flex>-->
            <v-flex xs4 class="pa-1">
              <v-text-field
                placeholder="Tipo de Resíduo"
                v-model="tipo"
                name="tipo"
                label="Tipo"
                prepend-inner-icon="mdi-magnify"
                clearable
                @click:clear="typing()"
                @input="typing()"
              />
            </v-flex>
            <v-flex xs4 class="pa-1">
              <v-text-field
                placeholder="Estado (Sólido, Líquido, etc...)"
                v-model="estado"
                name="estado"
                label="Estado"
                prepend-inner-icon="mdi-magnify"
                clearable
                @click:clear="typing()"
                @input="typing()"
              />
            </v-flex>
            <v-flex xs4 class="pa-1">
              <v-text-field
                placeholder="UM"
                v-model="um"
                name="um"
                label="Unidade Medida"
                prepend-inner-icon="mdi-magnify"
                clearable
                @click:clear="typing()"
                @input="typing()"
              />
            </v-flex>

            <v-flex xs6 shrink class="pa-1">
              <v-select
                label="Classe"
                v-model="classe"
                placeholder="Selecionar"
                :menu-props="{ 'offset-y': true }"
                :items="classes"
                return-object
                @input="typing()"
              >
              </v-select>
            </v-flex>

            <v-flex xs6 shrink class="pa-1">
              <v-select
                label="Status"
                v-model="status"
                placeholder="Selecionar"
                :menu-props="{ 'offset-y': true }"
                :items="getAllStatus"
                return-object
                @input="typing()"
              >
                <template v-slot:item="{ item }">
                  <v-icon style="margin-top: 4px;" v-if="item.color"
                          size="12"
                          :color="item.color"
                  >
                    mdi-checkbox-blank-circle
                  </v-icon>
                  <span class="body-2 ml-2"> {{ item.text }} </span>
                </template>
              </v-select>
            </v-flex>
            <v-flex xs6 class="ma-2" v-if="mtrs.length">
              <v-layout justify-end align-center>
                Exibindo {{ showingStart }} - {{ showingEnd }} de {{ totalItems }}
                <v-btn
                  icon
                  @click="pageNumber-=1"
                  :disabled="pageNumber <= 1"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn
                  icon
                  @click="pageNumber+=1"
                  :disabled="pageNumber >= totalPages"
                >
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-layout>
<!--              <v-btn icon @click="exportAdvancedFilter" v-if="showExportButton" class="float-right">-->
<!--                <v-icon>mdi-file-excel</v-icon>-->
<!--              </v-btn>-->
            </v-flex>
            <v-flex xs6 class="ma-2" v-if="mtrs.length">
              <v-layout justify-end align-center>
                <v-btn
                  v-if="showExportButton"
                  color="primary"
                  @click="exportAdvancedFilter"
                >
                  <v-icon color="white">mdi-file-excel</v-icon>
                  Exportar lista filtrada
                </v-btn>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-layout>

        <v-layout class="mtr-table">
          <v-flex>
            <v-data-table
              :headers="headers1"
              :items="mtrs"
              :pagination.sync="pagination"
              hide-actions
              no-data-text=""
              class="elevation-1"
            >
              <template slot="items" slot-scope="props">
                <td>{{ props.item.number }}</td>
              </template>
            </v-data-table>
          </v-flex>

          <v-flex xs8 style="overflow: auto">
            <v-data-table
              :headers="headers2"
              :items="mtrs"
              :loading="loading"
              :pagination.sync="pagination"
              hide-actions
              no-data-text="Nenhum MTR cadastrado"
              class="elevation-1"
            >
              <template slot="items" slot-scope="props">
                <td class="person-data">
                  <div class="name">
                    {{ props.item.generator_data.name }}
                    <p class="grey--text">
                      {{ formatCpfCnpj(props.item.generator_data.cpf_cnpj )}}
                    </p>
                  </div>
                </td>
                <td class="person-data">
                  <div class="name">
                    {{ props.item.receiver_data.name }}
                    <p class="grey--text">
                      {{ formatCpfCnpj(props.item.receiver_data.cpf_cnpj) }}
                    </p>
                  </div>
                </td>
                <td class="person-data">
                  <div class="name">
                    {{ props.item.transporter_data.name }}
                    <p class="grey--text">
                      {{ formatCpfCnpj(props.item.transporter_data.cpf_cnpj) }}
                    </p>
                  </div>
                </td>
                <td class="text-xs-right">
                  {{ formatDate(props.item.created_at) }}
                </td>
                <td class="text-xs-right">
                  {{ formatDate(props.item.received_at) || '-' }}
                </td>
              </template>
            </v-data-table>
          </v-flex>

          <v-flex>
            <v-data-table
              :headers="headers3"
              :items="mtrs"
              :pagination.sync="pagination"
              hide-actions
              no-data-text=""
              class="elevation-1"
            >
              <template slot="items" slot-scope="props">
                <td>
                  <v-icon
                    size="17"
                    :color="chipStatusColor(props.item.status)"
                  >
                    mdi-checkbox-blank-circle
                  </v-icon>
                  {{ translatedStatus(props.item.status) }}
                </td>
                <td class="text-xs-right">
                  <v-btn
                    small
                    icon
                    flat
                    :disabled="isRequesting"
                    v-if="isMtrReceived(props.item.status)"
                    :loading="downloadingMtrReceivementReportId === props.item.id"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          color="grey darken-1"
                          @click="downloadMtrReceivementReport(props.item.id)"
                        >
                          mdi-file
                        </v-icon>
                      </template>
                      <span>Relatório de Recebimento</span>
                    </v-tooltip>
                  </v-btn>

                  <v-btn small icon flat :disabled="isRequesting">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          color="grey darken-1"
                          @click="selectMtr(props.item)"
                        >
                          mdi-eye
                        </v-icon>
                      </template>
                      <span>Mais informações</span>
                    </v-tooltip>
                  </v-btn>

                  <MtrsOptionsDropdown
                    tooltip='Opções'
                    icon='mdi-dots-horizontal'
                    :disabled="isRequesting"
                    v-if="isMtrApproved(props.item.status)"
                    :loading="downloadingMtrReportId === props.item.id"
                  >
                    <MtrsOptionsDropdownItems @click="showMtrPdf(props.item.id)">Ver PDF</MtrsOptionsDropdownItems>
                    <MtrsOptionsDropdownItems @click="downloadMtrReport(props.item.id, props.item.number)" >Baixar</MtrsOptionsDropdownItems>
                    <MtrsOptionsDropdownItems v-if="props.item.cdf_id" @click="downloadCdfPdf(props.item.cdf_id)" >Baixar CDF</MtrsOptionsDropdownItems>
                  </MtrsOptionsDropdown>

                </td>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-container>
    </template>
  </in-layout>
</template>

<script>
import InLayout from '@/layouts/InLayout.vue';
import mtrService from '@/services/mtr-service';
import formatCpfCnpj from '@/utils/formatCpfCnpj';
import MtrDialog from '@/components/settings/MtrDialog.vue';
import ConfirmationDialog from '@/components/settings/ConfirmationDialog.vue';
import MtrsOptionsDropdown from '@/components/settings/MtrsOptionsDropdown.vue';
import MtrsOptionsDropdownItems from '@/components/settings/MtrsOptionsDropdownItems.vue';
import FormatedDatePicker from '@/components/base/FormatedDatePicker.vue';
import format from 'date-fns/format';
import { mapActions } from 'vuex';
import AdvancedFilterModal from "@/components/mtr/list/AdvancedFilterModal";
import * as fs from "file-saver";

export default {
  name: 'RegisteredMTRsList',
  components: {
    InLayout,
    mtrService,
    ConfirmationDialog,
    MtrDialog,
    FormatedDatePicker,
    MtrsOptionsDropdown,
    AdvancedFilterModal,
    MtrsOptionsDropdownItems,
  },
  created() {
    this.getClasses();
  },
  data() {
    return {
      classes:[],
      componentKey: 0,
      status: '',
      emittedStart: '',
      emittedEnd: '',
      loading: false,
      mtrs: [],
      mtr: '',
      tipo: '',
      classe: {text : '', value : ''},
      estado: '',
      um: '',
      totalItems: 0,
      totalPages: 0,
      pageNumber: 1,
      itemsPerPage: 20,
      sortBy: 'number',
      sortDirection: 'desc',
      interval: null,
      selectedMtr: {},
      isRequesting: false,
      downloadingMtrReportId: false,
      downloadingMtrReceivementReportId: null,
      showAdvancedFilterModal: false,
      showExportButton: false,
      formatCpfCnpj,
      fields: {
        initial_date: '',
        final_date: '',
        transporter: '',
        receiver: '',
        generator: '',
        temporary_storage: '',
      },
      format,
      headers1: [
        { text: 'ID', value: 'number' },
      ],
      headers2: [
        { text: 'Gerador', sortable: false },
        { text: 'Destinador', sortable: false },
        { text: 'Transportador', sortable: false },
        { text: 'Emitido em', align: 'right', value: 'created_at' },
        { text: 'Recebido em', align: 'right', value: 'received_at' },
      ],
      headers3: [
        {
          text: 'Status', align: 'center', sortable: false, class: 'status-col',
        },
        {
          text: 'Ações', align: 'center', sortable: false, class: 'actions-col',
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      toggleSnackbar: 'application/toggleSnackbar',
    }),
    cancelAdvancedFilter() {
      this.showAdvancedFilterModal = false;
    },
    filterAdvanced(fields){
      this.fields = fields || this.fields;
      this.showAdvancedFilterModal = false;
      this.getMtrs(1, this.fields)
    },
    limparAdvancedFilter(){
      this.fields = {};
      this.componentKey +=1;
      this.getMtrs(1, this.fields)
    },
    async getClasses(){
      try {
        const {data} = await mtrService.getClasses()
        const classes = [
          {
            text: 'Mostrar todos',
          },
          ...data.map( (classe) => ({
            text: classe.name,
            value: classe.name
          }))];
        this.classes = classes;
      } catch (e) {
        console.log(e);
      }
    },
    exportAdvancedFilter() {
      this.loading = true;
      mtrService
        .export({
          mtr: this.mtr || undefined,
          generator_data: this.fields.generator || undefined,
          transporter_data: this.fields.transporter || undefined,
          receiver_data: this.fields.receiver || undefined,
          temporary_storage_data: this.fields.temporary_storage || undefined,
          tipo: this.tipo || undefined,
          classe: this.classe.value || undefined,
          um: this.um || undefined, //Unidade de Medida
          estado: this.estado || undefined,
          status: this.status ? this.status.value : undefined,
          emittedStart: this.fields.initial_date ? `${this.fields.initial_date} 00:00:00` : undefined,
          emittedEnd: this.fields.final_date ? `${this.fields.final_date} 23:59:59` : undefined,
          sortBy: this.sortBy,
          sortDirection: this.sortDirection,
        })
        .then(({ data }) => {
          const blob = new Blob([data], {
            type: "application/octet-stream;charset=UTF-8"
          });
          fs.saveAs(blob, "mtrs.xlsx");
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.loading = false;
        })
    },
    getMtrs(pageNumber,fields = null) {
      this.fields = fields || this.fields;
      this.pageNumber = pageNumber || this.pageNumber;
      this.loading = true;
      this.showExportButton = false;
      mtrService
        .getMtrs({
          page: this.pageNumber,
          limit: this.itemsPerPage,
          mtr: this.mtr || undefined,
          generator_data: this.fields.generator || undefined,
          transporter_data: this.fields.transporter || undefined,
          receiver_data: this.fields.receiver || undefined,
          temporary_storage_data: this.fields.temporary_storage || undefined,
          tipo: this.tipo || undefined,
          classe: this.classe.value || undefined,
          um: this.um || undefined, //Unidade de Medida
          estado: this.estado || undefined,
          status: this.status ? this.status.value : undefined,
          emittedStart: this.fields.initial_date ? `${this.fields.initial_date} 00:00:00` : undefined,
          emittedEnd: this.fields.final_date ? `${this.fields.final_date} 23:59:59` : undefined,
          sortBy: this.sortBy,
          sortDirection: this.sortDirection,
        })
        .then(({ data }) => {
          this.mtrs = data.mtrs;
          this.totalItems = data.total_items;
          this.totalPages = data.total_pages;
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.showExportButton = !!this.status || !!this.mtr || this.tipo ||(this.classe && this.classe.value) || !!this.um || !!this.estado || !!this.fields.initial_date || !!this.fields.final_date || !!this.fields.generator || !!this.fields.transporter || !!this.fields.receiver || !!this.fields.temporary_storage;
          this.loading = false;
        })
    },
    formatDate(date) {
      if (!date) {
        return null;
      }
      return `${format(date, 'DD/MM/YYYY')}`;
    },
    selectMtr(mtr) {
      this.selectedMtr = mtr;
      this.$refs.showMtrDialog.open();
    },
    openAdvancedFilterModal() {
      this.showAdvancedFilterModal = true;
    },
    chipStatusColor(status) {
      return {
        to_receive: 'amber',
        received: 'blue',
        certified: 'green',
        rejected: 'red',
        cancelled: 'red',
        not_approved: 'red',
        received_by_temporary_storage: 'amber',
      }[status] || 'grey';
    },
    translatedStatus(status) {
      return {
        received: 'Recebido',
        to_receive: 'A receber',
        rejected: 'Rejeitado',
        cancelled: 'Cancelado',
        not_approved: 'Recusado',
        received_by_temporary_storage: 'Armazenamento Temporário',
      }[status] || 'Certificado';
    },
    isMtrApproved(status) {
      return status !== 'not_approved';
    },
    isMtrReceived(status) {
      return status === 'received';
    },
    typing() {
      clearInterval(this.interval);
      this.interval = setTimeout(() => {
        this.getMtrs(1);
      }, 500);
    },
    downloadMtrReport(mtrId, mtrNumber) {
      this.downloadingMtrReportId = mtrId;
      this.isRequesting = true;

      mtrService.mtrReport(mtrId)
        .then((data) => {
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(data);
          link.download = `${mtrNumber}.pdf`;
          link.click();
        }).catch((err) => {
        this.toggleSnackbar({
          show: true,
          timeout: 6000,
          color: 'error',
          message: err.message,
          messageIcon: 'mdi-alert-outline',
        });
      }).finally(() => {
        this.downloadingMtrReportId = false;
        this.isRequesting = false;
      });
    },
    downloadMtrReceivementReport(mtrId) {
      this.downloadingMtrReceivementReportId = mtrId;
      this.isRequesting = true;
      mtrService.mtrReceivementReport(mtrId)
        .then((data) => {
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(data);
          link.download = 'Relatório de Recebimento de MTR.pdf';
          link.click();
        })
        .catch((err) => {
          this.toggleSnackbar({
            show: true,
            timeout: 6000,
            color: 'error',
            message: err.message,
            messageIcon: 'mdi-alert-outline',
          });
        })
        .finally(() => {
          this.downloadingMtrReceivementReportId = false;
          this.isRequesting = false;
        });
    },
    showMtrPdf(mtrId) {
      this.$router.push({ name: 'Ver PDF MTR', params: { id: mtrId, document: 'mtr' } });
    },
    async downloadCdfPdf(cdfId) {
      this.downloadingMtrReceivementReportId = cdfId;
      this.isRequesting = true;
      const cdf = await mtrService.mtrCdfShow(cdfId);
      if (cdf) {
        mtrService.mtrCdfReport(cdfId)
          .then((data) => {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(data);
            link.download = cdf.number;
            link.click();
          })
          .catch((err) => {
            this.toggleSnackbar({
              show: true,
              timeout: 6000,
              color: 'error',
              message: err.message,
              messageIcon: 'mdi-alert-outline',
            });
          })
          .finally(() => {
            this.downloadingMtrReceivementReportId = false;
            this.isRequesting = false;
          });
      }
    },
  },
  computed: {
    showingStart() {
      return (this.pageNumber - 1 === 0) ? 1 : (this.pageNumber - 1) * this.itemsPerPage;
    },
    showingEnd() {
      if (this.totalPages === this.pageNumber) {
        return this.totalItems;
      }
      return (this.pageNumber * this.itemsPerPage);
    },
    getAllStatus() {
      const status = ['received', 'to_receive', 'received_by_temporary_storage', 'rejected', 'cancelled', 'not_approved', 'certified'];
      return [
        { text: 'Mostrar todos' },
        ...status.map(s => ({
          text: this.translatedStatus(s),
          color: this.chipStatusColor(s),
          value: s,
        })),
      ];
    },
    pagination: {
      get() {
        return {
          descending: this.sortDirection === 'desc',
          page: 1,
          rowsPerPage: this.itemsPerPage,
          sortBy: this.sortBy,
        };
      },
      set({ descending, sortBy }) {
        this.sortDirection = descending ? 'desc' : 'asc';
        this.sortBy = sortBy;
        this.getMtrs(1);
      },
    },
  },
  watch: {
    pageNumber() {
      this.getMtrs(this.pageNumber);
    },
  },
};
</script>

<style>
.actions-col {
  min-width: 180px;
}

.status-col {
  min-width: 140px;
}

.person-data {
  min-width: 250px;
}

.mtr-table .flex {
  padding: 1px !important;
}

.name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 27ch;
}

.name p {
  margin: 0;
}
</style>

