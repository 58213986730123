var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.is,
    {
      tag: "Component",
      staticClass: "options-dropdown-items",
      staticStyle: { padding: "0.5rem 1rem !important" },
      attrs: { to: _vm.to },
      on: { click: _vm.onClick },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }