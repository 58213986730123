import * as cpfUtil from '@fnando/cpf';
import * as cnpjUtil from '@fnando/cnpj';

const cpf = (value) => {
  if (!value) return '';
  return cpfUtil.format(value);
};

const cnpj = (value) => {
  if (!value) return '';
  return cnpjUtil.format(value);
};

const phone = (value) => {
  if (!value) return '-- ----- ----';
  return value.replace(/(\d{2})(\d{4,5})(\d{4})$/g, '($1) $2-$3');
};

const cep = value => value.replace(/(\d{5})(\d{3})$/g, '$1-$2');

export default {
  filters: {
    cpf,
    cnpj,
    phone,
    cep,
  },
};
