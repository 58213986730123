<template>
  <Component
		:is="is"
		:to="to"
		@click="onClick"
		class="options-dropdown-items"
		style="padding: 0.5rem 1rem !important;"
	>
    <slot />
  </Component>
</template>

<script>
export default {
  name: 'MtrsOptionsDropdown',
  props: {
    to: {
			type: [String, Object],
		},
  },
  data() {
    return {
    }
  },
  methods: {
    onClick(e) {
			this.$emit('click', e);
		}
  },
	computed: {
		is() {
			if (typeof this.to !== 'undefined') return 'RouterLink';
			return 'button';
		}
	}
}
</script>

<style lang="scss">
.options-dropdown-items {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.5rem 1rem !important;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: rgba(55, 65, 81, 1);
  cursor: pointer;

  &:hover {
    background-color: rgba(243, 244, 246, 1);
    color: rgba(17, 24, 39, 1);
  }
  &:focus {
    outline: none;
    background-color: rgba(243, 244, 246, 1);
    color: rgba(17, 24, 39, 1);
  }
}
</style>