<template>
  <v-dialog style="display: none;"
    v-model="show"
    persistent
    width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field class="date-picker-field"
        v-bind="attrs"
        v-on="on"

        v-model="formatedDate"
        :label="label"
        :placeholder="placeholder"
        append-icon="mdi-calendar"

        readonly clearable
        @click:clear="onSave('')"
      />
    </template>

    <v-date-picker
      :locale="locale"
      :min="min"
      :max="todayOrLess"
      v-model="date"
      scrollable
    >
      <v-spacer></v-spacer>
      <v-btn
        flat
        color="primary"
        @click="onCancel()"
      >
        Cancelar
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="onSave(date)"
      >
        OK
      </v-btn>
    </v-date-picker>
  </v-dialog>
</template>

<script>
import { formatBrazilianDate } from '@/mixins/formattedDateFilter';
import DateFns from 'date-fns';

export default {
  props: {
    label: String,
    placeholder: String,
    min: String,
    max: String,
    value: String,
    locale: {
      type: String,
      default: 'pt-br',
    },
  },
  data() {
    return {
      isShow: false,
      formatedDate: '',
      date: '',
    };
  },
  computed: {
    todayOrLess() {
      if (DateFns.isFuture(this.max) || !this.max) {
        return DateFns.format(new Date(), 'YYYY-MM-DD');
      }
      return DateFns.format(this.max, 'YYYY-MM-DD');
    },
    show: {
      get() {
        return this.isShow;
      },
      set(value) {
        this.isShow = value;

        if (value) {
          this.onOpen();
        } else {
          this.onClose();
        }
      },
    },
  },
  methods: {
    onSave(date) {
      if (date) {
        this.formatedDate = formatBrazilianDate(date);
      } else {
        this.formatedDate = '';
      }
      this.date = date;
      this.$emit('input', date);
      this.$emit('change');
      this.onClose()
    },
    onCancel() {
      this.onClose()
    },
    onOpen() {
      this.date = this.value
    },
    onClose() {
      this.date = ''
      this.isShow = false
    },
  },
};
</script>

<style lang="scss">
  .date-picker-field {
    label.v-label.v-label--active.theme--light {
      overflow: inherit !important;
    }
  }
</style>
