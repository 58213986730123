<template>
    <v-dialog
      v-model="show"
      :width="width"
      persistent
    >
      <v-toolbar class="headline font-weight-bold" color="white">
        {{ title }}
      </v-toolbar>

      <v-card :width="width" flat>
        <v-card-text class="py-0 px-4">
          <span class="description" v-if="toApprove">
            {{ description }}
          </span>
          <v-textarea
            v-else
            v-model="text"
            :label="label"
            name="text"
            class="pa-0 ma-0"
            :rules="rules"
            :maxlength="maxlength"
            :counter="maxlength"
            no-resize
            filled
          />
        </v-card-text>
        <v-card-actions class="pt-4 px-4">
          <v-layout justify-end>
            <v-btn flat @click="beforeCancel">
              Cancelar
            </v-btn>
            <v-btn
              v-if="toApprove"
              color="primary"
              @click="beforeConfirm"
            >
              Aceitar
            </v-btn>
            <v-btn
              v-else
              color="error"
              :disabled="!text.length"
              @click="beforeConfirm"
            >
              Recusar
            </v-btn>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import PersonService from '@/services/person-service';
import { isEmpty } from 'lodash';

export default {
  name: 'ConfirmationDialog',
  props: {
    toApprove: Boolean,
    width: {
      type: String,
      default: '400',
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: 'Escreva uma justificativa',
    },
    maxlength: {
      type: String,
    },
    rules: {
      type: Array,
    },
  },
  components: {
    PersonService,
  },
  data() {
    return {
      show: false,
      confirm: null,
      cancel: null,
      text: '',
      isEmpty,
    };
  },
  methods: {
    open(confirm = null, cancel = null) {
      this.show = true;

      this.confirm = confirm;
      this.cancel = cancel;
    },
    close() {
      this.show = false;
    },
    beforeCancel() {
      if (this.cancel) {
        this.cancel();
      }

      this.close();
    },
    beforeConfirm() {
      if (this.confirm) {
        this.confirm(this.text);
      }

      this.close();
    },
  },
};
</script>

<style>
  .description {
    font-size: 16px;
    line-height: 150%;
    color: rgba(0, 0, 0, 0.54);
  }
</style>
