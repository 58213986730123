<template>
  <div class="options-dropdown">
    <v-btn
      small
      icon
      flat
      @click.stop.prevent="toggle()"
      :loading="loading"
      :disabled="disabled"
    >
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">{{ icon }}</v-icon>
        </template>
        <span>{{ tooltip }}</span>
      </v-tooltip>
    </v-btn>
    <div
      v-show="isOpen"
      class="options-dropwdown-box"
    >
      <div class="options-dropdown-box-area">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MtrsOptionsDropdown",
  props: {
    icon: {
      type: String,
      required: true,
    },
    tooltip: {
      type: String,
      default: "",
    },
    loading: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		},
  },
  data() {
    return {
      isOpen: false,
    };
  },

  watch: {
    isOpen(value) {
      if (value) {
        this.$root.$emit("dropdown::open", this);
      }
    },
  },
  created() {
    this.$root.$on("dropdown::open", this.rootCloseListener);
  },
  mounted() {
    document.addEventListener("click", this.clickOutListener);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.clickOutListener);
  },

  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
    close() {
      this.isOpen = false;
    },
    clickOutListener(evt) {
      if (!this.$el.contains(evt.target)) {
        this.close();
      }
    },
    rootCloseListener(vm) {
      if (vm !== this) {
        this.close();
      }
    },
  },
};
</script>

<style lang="scss">
.options-dropdown{
  position: relative;
  display: inline-block;
  text-align: left;
}

.options-dropwdown-box {
  transform-origin: top right;
  position: absolute;
  right: 0;
  margin-top: 0;
  width: 14rem;
  border-radius: 0.375rem;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  z-index: 99 !important;
}

.options-dropdown-box-area {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  border-radius: 0.375rem;
  background: #fff;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
}
</style>