var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "in-layout",
    [
      _c(
        "template",
        { slot: "list" },
        [
          _c(
            "v-container",
            { staticClass: "px-4", attrs: { "grid-list-md": "" } },
            [
              _c("mtr-dialog", {
                ref: "showMtrDialog",
                attrs: { selectedMtr: _vm.selectedMtr },
              }),
              _c(
                "v-layout",
                { staticClass: "ma-2", attrs: { column: "" } },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "mb-4",
                      staticStyle: { cursor: "pointer" },
                      attrs: { tag: "span", to: { name: "Configurações" } },
                    },
                    [
                      _c("v-icon", { attrs: { size: "18" } }, [
                        _vm._v("mdi-chevron-left"),
                      ]),
                      _vm._v(
                        "\n            Voltar para configurações\n          "
                      ),
                    ],
                    1
                  ),
                  _c("advanced-filter-modal", {
                    key: _vm.componentKey,
                    attrs: { xs4: "", show: _vm.showAdvancedFilterModal },
                    on: {
                      close: function ($event) {
                        _vm.showAdvancedFilterModal = false
                      },
                      "emit-fields": _vm.filterAdvanced,
                    },
                  }),
                  _c("h1", [_vm._v("MTRs cadastrados")]),
                  _c(
                    "v-layout",
                    { attrs: { row: "", "align-end": "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { staticClass: "pa-1", attrs: { xs7: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              placeholder: "Pesquisa MTR ...",
                              name: "search",
                              label: "Pesquisar MTR",
                              "prepend-inner-icon": "mdi-magnify",
                              clearable: "",
                            },
                            on: {
                              "click:clear": function ($event) {
                                return _vm.typing()
                              },
                              input: function ($event) {
                                return _vm.typing()
                              },
                            },
                            model: {
                              value: _vm.mtr,
                              callback: function ($$v) {
                                _vm.mtr = $$v
                              },
                              expression: "mtr",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "pa-1", attrs: { xs2: "" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: { click: _vm.openAdvancedFilterModal },
                            },
                            [
                              _c("v-icon", { attrs: { color: "white" } }, [
                                _vm._v("mdi-magnify"),
                              ]),
                              _vm._v(
                                "\n                Busca Avançada\n              "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "pa-1", attrs: { xs3: "" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { xs5: "", color: "primary" },
                              on: { click: _vm.limparAdvancedFilter },
                            },
                            [
                              _c("v-icon", { attrs: { color: "white" } }, [
                                _vm._v("mdi-close"),
                              ]),
                              _vm._v(
                                "\n                Limpar Filtros Avançados\n              "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "pa-1", attrs: { xs4: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              placeholder: "Tipo de Resíduo",
                              name: "tipo",
                              label: "Tipo",
                              "prepend-inner-icon": "mdi-magnify",
                              clearable: "",
                            },
                            on: {
                              "click:clear": function ($event) {
                                return _vm.typing()
                              },
                              input: function ($event) {
                                return _vm.typing()
                              },
                            },
                            model: {
                              value: _vm.tipo,
                              callback: function ($$v) {
                                _vm.tipo = $$v
                              },
                              expression: "tipo",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "pa-1", attrs: { xs4: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              placeholder: "Estado (Sólido, Líquido, etc...)",
                              name: "estado",
                              label: "Estado",
                              "prepend-inner-icon": "mdi-magnify",
                              clearable: "",
                            },
                            on: {
                              "click:clear": function ($event) {
                                return _vm.typing()
                              },
                              input: function ($event) {
                                return _vm.typing()
                              },
                            },
                            model: {
                              value: _vm.estado,
                              callback: function ($$v) {
                                _vm.estado = $$v
                              },
                              expression: "estado",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "pa-1", attrs: { xs4: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              placeholder: "UM",
                              name: "um",
                              label: "Unidade Medida",
                              "prepend-inner-icon": "mdi-magnify",
                              clearable: "",
                            },
                            on: {
                              "click:clear": function ($event) {
                                return _vm.typing()
                              },
                              input: function ($event) {
                                return _vm.typing()
                              },
                            },
                            model: {
                              value: _vm.um,
                              callback: function ($$v) {
                                _vm.um = $$v
                              },
                              expression: "um",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "pa-1", attrs: { xs6: "", shrink: "" } },
                        [
                          _c("v-select", {
                            attrs: {
                              label: "Classe",
                              placeholder: "Selecionar",
                              "menu-props": { "offset-y": true },
                              items: _vm.classes,
                              "return-object": "",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.typing()
                              },
                            },
                            model: {
                              value: _vm.classe,
                              callback: function ($$v) {
                                _vm.classe = $$v
                              },
                              expression: "classe",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "pa-1", attrs: { xs6: "", shrink: "" } },
                        [
                          _c("v-select", {
                            attrs: {
                              label: "Status",
                              placeholder: "Selecionar",
                              "menu-props": { "offset-y": true },
                              items: _vm.getAllStatus,
                              "return-object": "",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.typing()
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "item",
                                fn: function ({ item }) {
                                  return [
                                    item.color
                                      ? _c(
                                          "v-icon",
                                          {
                                            staticStyle: {
                                              "margin-top": "4px",
                                            },
                                            attrs: {
                                              size: "12",
                                              color: item.color,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                    mdi-checkbox-blank-circle\n                  "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c("span", { staticClass: "body-2 ml-2" }, [
                                      _vm._v(" " + _vm._s(item.text) + " "),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.status,
                              callback: function ($$v) {
                                _vm.status = $$v
                              },
                              expression: "status",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.mtrs.length
                        ? _c(
                            "v-flex",
                            { staticClass: "ma-2", attrs: { xs6: "" } },
                            [
                              _c(
                                "v-layout",
                                {
                                  attrs: {
                                    "justify-end": "",
                                    "align-center": "",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                Exibindo " +
                                      _vm._s(_vm.showingStart) +
                                      " - " +
                                      _vm._s(_vm.showingEnd) +
                                      " de " +
                                      _vm._s(_vm.totalItems) +
                                      "\n                "
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        icon: "",
                                        disabled: _vm.pageNumber <= 1,
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.pageNumber -= 1
                                        },
                                      },
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-chevron-left"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        icon: "",
                                        disabled:
                                          _vm.pageNumber >= _vm.totalPages,
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.pageNumber += 1
                                        },
                                      },
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-chevron-right"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.mtrs.length
                        ? _c(
                            "v-flex",
                            { staticClass: "ma-2", attrs: { xs6: "" } },
                            [
                              _c(
                                "v-layout",
                                {
                                  attrs: {
                                    "justify-end": "",
                                    "align-center": "",
                                  },
                                },
                                [
                                  _vm.showExportButton
                                    ? _c(
                                        "v-btn",
                                        {
                                          attrs: { color: "primary" },
                                          on: {
                                            click: _vm.exportAdvancedFilter,
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { color: "white" } },
                                            [_vm._v("mdi-file-excel")]
                                          ),
                                          _vm._v(
                                            "\n                  Exportar lista filtrada\n                "
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-layout",
                { staticClass: "mtr-table" },
                [
                  _c(
                    "v-flex",
                    [
                      _c("v-data-table", {
                        staticClass: "elevation-1",
                        attrs: {
                          headers: _vm.headers1,
                          items: _vm.mtrs,
                          pagination: _vm.pagination,
                          "hide-actions": "",
                          "no-data-text": "",
                        },
                        on: {
                          "update:pagination": function ($event) {
                            _vm.pagination = $event
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "items",
                            fn: function (props) {
                              return [
                                _c("td", [_vm._v(_vm._s(props.item.number))]),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { staticStyle: { overflow: "auto" }, attrs: { xs8: "" } },
                    [
                      _c("v-data-table", {
                        staticClass: "elevation-1",
                        attrs: {
                          headers: _vm.headers2,
                          items: _vm.mtrs,
                          loading: _vm.loading,
                          pagination: _vm.pagination,
                          "hide-actions": "",
                          "no-data-text": "Nenhum MTR cadastrado",
                        },
                        on: {
                          "update:pagination": function ($event) {
                            _vm.pagination = $event
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "items",
                            fn: function (props) {
                              return [
                                _c("td", { staticClass: "person-data" }, [
                                  _c("div", { staticClass: "name" }, [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(props.item.generator_data.name) +
                                        "\n                    "
                                    ),
                                    _c("p", { staticClass: "grey--text" }, [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(
                                            _vm.formatCpfCnpj(
                                              props.item.generator_data.cpf_cnpj
                                            )
                                          ) +
                                          "\n                    "
                                      ),
                                    ]),
                                  ]),
                                ]),
                                _c("td", { staticClass: "person-data" }, [
                                  _c("div", { staticClass: "name" }, [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(props.item.receiver_data.name) +
                                        "\n                    "
                                    ),
                                    _c("p", { staticClass: "grey--text" }, [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(
                                            _vm.formatCpfCnpj(
                                              props.item.receiver_data.cpf_cnpj
                                            )
                                          ) +
                                          "\n                    "
                                      ),
                                    ]),
                                  ]),
                                ]),
                                _c("td", { staticClass: "person-data" }, [
                                  _c("div", { staticClass: "name" }, [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          props.item.transporter_data.name
                                        ) +
                                        "\n                    "
                                    ),
                                    _c("p", { staticClass: "grey--text" }, [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(
                                            _vm.formatCpfCnpj(
                                              props.item.transporter_data
                                                .cpf_cnpj
                                            )
                                          ) +
                                          "\n                    "
                                      ),
                                    ]),
                                  ]),
                                ]),
                                _c("td", { staticClass: "text-xs-right" }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.formatDate(props.item.created_at)
                                      ) +
                                      "\n                "
                                  ),
                                ]),
                                _c("td", { staticClass: "text-xs-right" }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.formatDate(
                                          props.item.received_at
                                        ) || "-"
                                      ) +
                                      "\n                "
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    [
                      _c("v-data-table", {
                        staticClass: "elevation-1",
                        attrs: {
                          headers: _vm.headers3,
                          items: _vm.mtrs,
                          pagination: _vm.pagination,
                          "hide-actions": "",
                          "no-data-text": "",
                        },
                        on: {
                          "update:pagination": function ($event) {
                            _vm.pagination = $event
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "items",
                            fn: function (props) {
                              return [
                                _c(
                                  "td",
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: {
                                          size: "17",
                                          color: _vm.chipStatusColor(
                                            props.item.status
                                          ),
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                    mdi-checkbox-blank-circle\n                  "
                                        ),
                                      ]
                                    ),
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.translatedStatus(
                                            props.item.status
                                          )
                                        ) +
                                        "\n                "
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "td",
                                  { staticClass: "text-xs-right" },
                                  [
                                    _vm.isMtrReceived(props.item.status)
                                      ? _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              small: "",
                                              icon: "",
                                              flat: "",
                                              disabled: _vm.isRequesting,
                                              loading:
                                                _vm.downloadingMtrReceivementReportId ===
                                                props.item.id,
                                            },
                                          },
                                          [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { bottom: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function ({ on }) {
                                                        return [
                                                          _c(
                                                            "v-icon",
                                                            _vm._g(
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "grey darken-1",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.downloadMtrReceivementReport(
                                                                        props
                                                                          .item
                                                                          .id
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _vm._v(
                                                                "\n                          mdi-file\n                        "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    "Relatório de Recebimento"
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          small: "",
                                          icon: "",
                                          flat: "",
                                          disabled: _vm.isRequesting,
                                        },
                                      },
                                      [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function ({ on }) {
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          {
                                                            attrs: {
                                                              color:
                                                                "grey darken-1",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.selectMtr(
                                                                  props.item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _vm._v(
                                                            "\n                          mdi-eye\n                        "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("Mais informações"),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.isMtrApproved(props.item.status)
                                      ? _c(
                                          "MtrsOptionsDropdown",
                                          {
                                            attrs: {
                                              tooltip: "Opções",
                                              icon: "mdi-dots-horizontal",
                                              disabled: _vm.isRequesting,
                                              loading:
                                                _vm.downloadingMtrReportId ===
                                                props.item.id,
                                            },
                                          },
                                          [
                                            _c(
                                              "MtrsOptionsDropdownItems",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.showMtrPdf(
                                                      props.item.id
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("Ver PDF")]
                                            ),
                                            _c(
                                              "MtrsOptionsDropdownItems",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.downloadMtrReport(
                                                      props.item.id,
                                                      props.item.number
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("Baixar")]
                                            ),
                                            props.item.cdf_id
                                              ? _c(
                                                  "MtrsOptionsDropdownItems",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.downloadCdfPdf(
                                                          props.item.cdf_id
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("Baixar CDF")]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }