var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "700", scrollable: "" },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "headline green darken-2 white--text" },
                [
                  _c(
                    "v-layout",
                    {
                      attrs: {
                        "align-center": "",
                        "justify-space-between": "",
                      },
                    },
                    [
                      _vm._v(
                        "\n          Manifesto de Transporte de Resíduos e Rejeitos\n          "
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { flat: "", icon: "" },
                          on: { click: _vm.close },
                        },
                        [
                          _c("v-icon", { attrs: { color: "white" } }, [
                            _vm._v("mdi-close"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    { staticClass: "py-0", attrs: { "grid-list-lg": "" } },
                    [
                      _c(
                        "v-flex",
                        {
                          staticClass: "py-2 subheading font-weight-bold",
                          attrs: { xs12: "" },
                        },
                        [
                          _vm._v(
                            "\n            MTR " +
                              _vm._s(_vm.selectedMtr.number) +
                              "\n          "
                          ),
                        ]
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "py-2", attrs: { xs12: "" } },
                        [
                          _c(
                            "span",
                            { staticClass: "subheading font-weight-bold" },
                            [_vm._v("Emitido em: ")]
                          ),
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.formatDateTime(_vm.selectedMtr.created_at)
                              ) +
                              "\n          "
                          ),
                        ]
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "py-2", attrs: { xs12: "" } },
                        [
                          _c(
                            "span",
                            { staticClass: "subheading font-weight-bold" },
                            [_vm._v("Responsável pela emissão: ")]
                          ),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.selectedMtr.responsible_name) +
                              "\n          "
                          ),
                        ]
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "py-2", attrs: { xs12: "" } },
                        [
                          _c(
                            "span",
                            { staticClass: "subheading font-weight-bold" },
                            [_vm._v("Cargo: ")]
                          ),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.selectedMtr.responsible_role) +
                              "\n          "
                          ),
                        ]
                      ),
                      _vm._l(
                        _vm.peopleIdentification,
                        function (people, index) {
                          return _c(
                            "v-layout",
                            { key: index, attrs: { column: "" } },
                            [
                              _c("v-flex", { attrs: { xs12: "" } }, [
                                _c(
                                  "h2",
                                  {
                                    staticClass: "green--text font-weight-bold",
                                  },
                                  [_vm._v(_vm._s(people.title))]
                                ),
                              ]),
                              _c("v-flex", { attrs: { xs12: "" } }, [
                                _c(
                                  "span",
                                  {
                                    staticClass: "subheading font-weight-bold",
                                  },
                                  [_vm._v("Razão Social: ")]
                                ),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(people.name) +
                                    "\n            "
                                ),
                              ]),
                              _c("v-flex", { attrs: { xs12: "" } }, [
                                _c(
                                  "span",
                                  {
                                    staticClass: "subheading font-weight-bold",
                                  },
                                  [_vm._v("CPF/CNPJ: ")]
                                ),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.formatCpfCnpj(people.cpf_cnpj)) +
                                    "\n            "
                                ),
                              ]),
                              _c("v-flex", { attrs: { xs12: "" } }, [
                                _c(
                                  "span",
                                  {
                                    staticClass: "subheading font-weight-bold",
                                  },
                                  [_vm._v("Endereço: ")]
                                ),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.address(people.address)) +
                                    "\n            "
                                ),
                              ]),
                              _c("v-flex", { attrs: { xs6: "" } }, [
                                _c(
                                  "span",
                                  {
                                    staticClass: "subheading font-weight-bold",
                                  },
                                  [_vm._v("Município: ")]
                                ),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(people.address.city.name) +
                                    "\n            "
                                ),
                              ]),
                              _c("v-flex", { attrs: { xs6: "" } }, [
                                _c(
                                  "span",
                                  {
                                    staticClass: "subheading font-weight-bold",
                                  },
                                  [_vm._v("UF: ")]
                                ),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(people.address.federal_unit.name) +
                                    "\n            "
                                ),
                              ]),
                              _c("v-flex", { attrs: { xs12: "" } }, [
                                _c(
                                  "span",
                                  {
                                    staticClass: "subheading font-weight-bold",
                                  },
                                  [_vm._v("Celular: ")]
                                ),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.formatPhone(people.cellphone)) +
                                    "\n            "
                                ),
                              ]),
                              _c("v-divider"),
                            ],
                            1
                          )
                        }
                      ),
                      !!_vm.selectedMtr.comment
                        ? _c(
                            "v-flex",
                            {
                              staticClass: "py-2 text-xs-justify",
                              attrs: { xs12: "" },
                            },
                            [
                              _c(
                                "h2",
                                { staticClass: "green--text font-weight-bold" },
                                [_vm._v("Observações do Gerador")]
                              ),
                              _c("span", { staticClass: "pa-2" }, [
                                _vm._v(_vm._s(_vm.selectedMtr.comment)),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "v-flex",
                        { staticClass: "py-2", attrs: { xs12: "" } },
                        [
                          _c(
                            "h2",
                            {
                              staticClass: "green--text pb-2 font-weight-bold",
                            },
                            [_vm._v("Identificação dos Resíduos")]
                          ),
                          _c("v-data-table", {
                            staticClass: "elevation-2",
                            attrs: {
                              headers: _vm.residuesHeader,
                              items: _vm.selectedMtr.mtr_residues,
                              "hide-actions": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "items",
                                fn: function (props) {
                                  return [
                                    _c(
                                      "td",
                                      { staticClass: "text-xs-center" },
                                      [_vm._v(_vm._s(props.item.id))]
                                    ),
                                    _c(
                                      "td",
                                      { staticClass: "text-xs-center" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.formatResidueData(
                                              props.item.residue_data
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      { staticClass: "text-xs-center" },
                                      [
                                        _vm._v(
                                          _vm._s(props.item.physical_state)
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      { staticClass: "text-xs-center" },
                                      [_vm._v(_vm._s(props.item.residue_class))]
                                    ),
                                    _c(
                                      "td",
                                      { staticClass: "text-xs-center" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.formatResidueQuantity(
                                              props.item
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      { staticClass: "text-xs-center" },
                                      [
                                        _vm._v(
                                          _vm._s(props.item.disposal_technology)
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }