<template>
  <section>
    <v-dialog
      v-model="show"
      width="700"
      scrollable
    >
      <v-card>
        <v-card-title class="headline green darken-2 white--text">
          <v-layout align-center justify-space-between>
            Manifesto de Transporte de Resíduos e Rejeitos
            <v-btn flat icon @click="close">
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </v-layout>
        </v-card-title>

        <v-card-text>
          <v-container grid-list-lg class="py-0">
            <v-flex xs12 class="py-2 subheading font-weight-bold">
              MTR {{ selectedMtr.number }}
            </v-flex>

            <v-flex xs12 class="py-2">
              <span class="subheading font-weight-bold">Emitido em: </span>
              {{ formatDateTime(selectedMtr.created_at) }}
            </v-flex>

            <v-flex xs12 class="py-2">
              <span class="subheading font-weight-bold">Responsável pela emissão: </span>
              {{ selectedMtr.responsible_name }}
            </v-flex>

            <v-flex xs12 class="py-2">
              <span class="subheading font-weight-bold">Cargo: </span>
              {{ selectedMtr.responsible_role }}
            </v-flex>

            <v-layout
              column
              v-for="(people, index) in peopleIdentification"
              :key="index"
            >
              <v-flex xs12>
                <h2 class="green--text font-weight-bold">{{ people.title }}</h2>
              </v-flex>

              <v-flex xs12>
                <span class="subheading font-weight-bold">Razão Social: </span>
                {{ people.name }}
              </v-flex>

              <v-flex xs12>
                <span class="subheading font-weight-bold">CPF/CNPJ: </span>
                {{ formatCpfCnpj(people.cpf_cnpj) }}
              </v-flex>

              <v-flex xs12>
                <span class="subheading font-weight-bold">Endereço: </span>
                {{ address(people.address) }}
              </v-flex>

              <v-flex xs6>
                <span class="subheading font-weight-bold">Município: </span>
                {{ people.address.city.name }}
              </v-flex>

              <v-flex xs6>
                <span class="subheading font-weight-bold">UF: </span>
                {{ people.address.federal_unit.name }}
              </v-flex>

              <v-flex xs12>
                <span class="subheading font-weight-bold">Celular: </span>
                {{ formatPhone(people.cellphone) }}
              </v-flex>

              <v-divider/>
            </v-layout>

            <v-flex xs12 class="py-2 text-xs-justify" v-if="!!selectedMtr.comment">
              <h2 class="green--text font-weight-bold">Observações do Gerador</h2>
              <span class="pa-2">{{ selectedMtr.comment }}</span>
            </v-flex>

            <v-flex xs12 class="py-2">
              <h2 class="green--text pb-2 font-weight-bold">Identificação dos Resíduos</h2>

              <v-data-table
                :headers="residuesHeader"
                :items="selectedMtr.mtr_residues"
                hide-actions
                class="elevation-2"
              >
                <template v-slot:items="props">
                  <td class="text-xs-center">{{ props.item.id }}</td>
                  <td class="text-xs-center">{{ formatResidueData(props.item.residue_data) }}</td>
                  <td class="text-xs-center">{{ props.item.physical_state }}</td>
                  <td class="text-xs-center">{{ props.item.residue_class }}</td>
                  <td class="text-xs-center">{{ formatResidueQuantity(props.item) }}</td>
                  <td class="text-xs-center">{{ props.item.disposal_technology }}</td>
                </template>
              </v-data-table>
            </v-flex>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import formatCpfCnpj from '@/utils/formatCpfCnpj';
import formatters from '@/utils/formatters';
import format from 'date-fns/format';
import mtrService from '@/services/mtr-service';

export default {
  name: 'MtrDialog',
  props: ['selectedMtr'],
  data() {
    return {
      formatCpfCnpj,
      formatters,
      show: false,
      loading: false,
      format,
      residuesHeader: [
        { text: 'Item', align: 'center', sortable: false },
        { text: 'Cód IBAMA e denominação', align: 'center', sortable: false },
        { text: 'Estado físico', align: 'center', sortable: false },
        { text: 'Classe', align: 'center', sortable: false },
        { text: 'Quantidade', align: 'center', sortable: false },
        { text: 'Tecnologia', align: 'center', sortable: false },
      ],
    };
  },
  methods: {
    open() {
      this.show = true;
    },
    close() {
      this.show = false;
    },
    address(address) {
      const zipCode = this.formatters.filters.cep(address.zip_code);
      return `${address.street}, Nº ${address.number}, ${address.neighborhood} (${zipCode})`;
    },
    formatDateTime(date) {
      return `${format(date, 'DD/MM/YYYY')} às ${format(date, 'HH:mm')}`;
    },
    formatResidueData(residue) {
      return `${residue.code} - ${residue.name}`;
    },
    formatResidueQuantity(residue) {
      if (!!residue.quantity && !!residue.measure_unit) {
        return `${residue.quantity} (${residue.measure_unit})`;
      }
      return 'Não definida';
    },
    formatPhone(phone) {
      return this.formatters.filters.phone(phone);
    },
  },
  computed: {
    peopleIdentification() {
      const {
        generator_data: generatorData,
        temporary_storage_data: temporaryStorageData,
        transporter_data: transporterData,
        receiver_data: receiverData,
      } = this.selectedMtr;

      const peoples = [
        { title: 'Identificação do Gerador', ...generatorData },
        { title: 'Identificação do Armazenador Temporário (AT)', ...temporaryStorageData },
        { title: 'Identificação do Transportador', ...transporterData },
        { title: 'Identificação do Destinador', ...receiverData },
      ];
      return peoples.filter(el => el.id);
    },
  },
};
</script>
