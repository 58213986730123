var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "options-dropdown" },
    [
      _c(
        "v-btn",
        {
          attrs: {
            small: "",
            icon: "",
            flat: "",
            loading: _vm.loading,
            disabled: _vm.disabled,
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.toggle()
            },
          },
        },
        [
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on }) {
                    return [
                      _c("v-icon", _vm._g({}, on), [_vm._v(_vm._s(_vm.icon))]),
                    ]
                  },
                },
              ]),
            },
            [_c("span", [_vm._v(_vm._s(_vm.tooltip))])]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isOpen,
              expression: "isOpen",
            },
          ],
          staticClass: "options-dropwdown-box",
        },
        [
          _c(
            "div",
            { staticClass: "options-dropdown-box-area" },
            [_vm._t("default")],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }